// Tasks module
import axios from "axios";
import store from "/src/store/index";
const resource_uri = "/listaragenda";
const grava_prontuario = "/prontuario";

const state = {
  agendaDia: [],
  diaSelecionado: new Date(),
  permissao: {},
  permissaoProntuario: {},
};

const getters = {
  getPermissao: (state) => state.permissao,
  getPermissao: (state) => state.permissaoProntuario,
  getAgendaDia: (state) => state.agendaDia,
  diaSelecionado: (state) => state.diaSelecionado,
  getPaciente: (state) => (Id) => {
    if (Id == null || Id == "") {
      return null;
    }
    var filtro = state.agendaDia.filter((t) => t.Id === Number(Id));
    if (filtro.length > 0) {
      return filtro[0];
    }
    return null;
  },
};

const actions = {
  async fetchAgendaDia({ commit }, { urlBackEnd, idMedico , dia , qtdDias , }) {
    
    const response = await axios.post(urlBackEnd+resource_uri,{
    "idMedico":idMedico,
    "data": dia,
    "qtdDias":0,
    "emailUsuario": store.state.configuracoes.email,
    "mostrarHVazio": false
},{ headers: { Authorization: `Bearer ${store.state.configuracoes.tokenBack}`, 'Content-Type': 'application/json' } });
     commit("setAgendaDia", response.data)
    
     const permissaoProntuario = await axios.get(urlBackEnd+"/dadosvisiveis",{ headers: { Authorization: `Bearer ${store.state.configuracoes.tokenBack}`, 'Content-Type': 'application/json' } });
     commit("setpermissaoProntuario", permissaoProntuario.data)
  },

  async gravaProntuario({ commit }, { urlBackEnd, chave, idMedico, emailUsuario, data, hora, texto }) {
    const response = await axios.post(urlBackEnd+grava_prontuario,{
      "chave": chave,  
      "idMedico":idMedico,
      "emailUsuario": emailUsuario,
      "data": data,
      "hora": hora,
      "texto": texto,
},{ headers: { Authorization: `Bearer ${store.state.configuracoes.tokenBack}`, 'Content-Type': 'application/json' } });
     commit("resposta", response.data)
  },

  async agendaStatus({ commit }, { urlBackEnd, idPaciente , status }) {
    const response = await axios.post(urlBackEnd+"/status",{
      "chave": idPaciente,
      "status": status
},{ headers: { Authorization: `Bearer ${store.state.configuracoes.tokenBack}`, 'Content-Type': 'application/json' } });
  
  },

  async agendaPermissao({ commit }, { urlBackEnd }) {
    const response = await axios.get(urlBackEnd+"/dadosvisiveis",{ headers: { Authorization: `Bearer ${store.state.configuracoes.tokenBack}`, 'Content-Type': 'application/json' } });
    commit("setPermissao", response.data)
  },   
};

const mutations = {
  setAgendaDia: (state, agendaDia) => (state.agendaDia = agendaDia),
  setDiaSelecinado: (state, diaSelecionado) => (state.diaSelecionado = diaSelecionado),
  setPermissao: (state, permissao) => (state.permissao = permissao),
  setpermissaoProntuario: (state, permissaoProntuario) => (state.permissaoProntuario = permissaoProntuario),
};

export default {
  state,
  actions,
  mutations,
  getters,
};
